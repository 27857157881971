.Product {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.Product_button-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  padding: 0;
}

.Product_top {
  display: flex;
  justify-content: start;
  gap: 40px;
  margin: 0;
  padding: 0 0 40px;
}

.Product_top_main-image {
  max-width: 500px;
  width: 40vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.Product_image-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
  flex-wrap: wrap;
}

.Product_top_image {
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.Product_top_image:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Product_title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 20px;
}

.Product_data {
  text-align: left;
}

.Product_text {
  font-size: 1rem;
  padding: 0;
}

.Product_span {
  font-weight: 600;
  margin-right: 5px;
}

.Product_button {
  font-size: 1rem;
  background-color: #3d73aa;
  padding: 15px 30px;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;
  transition: all 0.3s;
}

.Product_button:hover {
  opacity: 0.8;
}

.Product_button-remove {
  background-color: #bb4d4d;
}

@media only screen and (max-width: 960px) {
  .Product_top {
    gap: 15px;
    margin: 0 20px;
    padding: 10px 0;
  }

  .Product_top_main-image {
    width: 50vw;
  }
}

@media only screen and (max-width: 760px) {
  .Product_item {
    max-width: 130px;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.4);
    padding: 10px;
    margin: 5px;
  }

  .Product_top {
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 10px;
  }

  .Product_top_main-image {
    width: 80vw;
  }

  .Product_text {
    font-size: 1.2rem;
    margin-bottom: 5px;
    padding: 10px;
    border-bottom: 1px solid rgba(140, 140, 140, 0.3);
  }

  .Product_button-container {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .Product_path {
    padding: 10px;
  }

  .Product_top {
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 10px;
  }

  .Product_top_main-image {
    width: 100%;
  }

  .Product_top_image {
    width: 15%;
  }

  .Product_button-container {
    text-align: center;
    margin: 10px 0;
    padding: 10px 0 0;
  }

  .Product_button {
    padding: 10px 20px;
  }

  .Product_related {
    padding: 20px 5px 0;
  }

  .Product_others {
    padding: 20px 5px;
  }

  .Product_item {
    max-width: 140px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.2);
    padding: 5px;
    margin: 20px 5px;
  }
}
