.Create {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.Create_top-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.Create_top {
  display: flex;
  justify-content: start;
  gap: 40px;
  margin: 0;
  padding: 0 0 40px;
}

.Create_main-image {
  max-height: 502px;
  max-width: 500px;
  width: 40vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.Create_top_main-image_empty {
  height: 502px;
  max-width: 500px;
  width: 40vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  background-color: rgb(216, 216, 216);
}

.Create_top_images_container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  flex-wrap: wrap;
}

.Create_top_image-container {
  position: relative;
}

.Create_top_image {
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.Create_top_image:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Create_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Create_text-container {
  max-width: 595px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(128, 128, 128, 0.1);
  gap: 5px;
  margin-top: 20px;
  padding: 10px 0 0;
}

.Create_text {
  font-size: 1.1rem;
  margin: 0 10px 0;
  padding: 0;
}
