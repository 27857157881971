.Login {
  height: 90vh;
  background-image: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../images/logo/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
